export const xsmall = 'max-width: 350px';
export const small = 'max-width: 767px';
export const medium = 'max-width: 1024px';
export const large = 'max-width: 1366px';
export const xlarge = 'min-width: 1367px';
export const xsmallDown = 'max-width: 350px';
export const smallDown = 'max-width: 768px';
export const mediumDown = 'max-width: 1024px';
export const largeDown = 'max-width: 1366px';
export const xlargeDown = 'max-width: 1600px';
export const xsmallUp = 'min-width: 0px';
export const smallUp = 'min-width: 350px';
export const mediumUp = 'min-width: 767px';
export const largeUp = 'min-width: 1024px';
export const xlargeUp = 'min-width: 1366px';
