import { motion } from 'framer-motion';
import r from 'render-dom';
import styled from 'styled-components';

// import Button from 'components/ui/Button';
import { mediumUp } from 'constants/mediaQueries';

const Container = styled.section`
    text-align: center;

    @media(${mediumUp}) {
        margin: auto;
        max-width: 500px;
    }

    button {
        margin-top: 35px;
    }

    h3 {
        font-size: 21px;
        margin: 0 0 15px;

        @media(${mediumUp}) {
            font-size: 28px;
            margin: 25px 0;
        }
    }

    .number-progress {
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }

    .option-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;

        .option {
            align-items: center;
            border: 2px solid #bbb;
            border-radius: 7px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            margin: 10px 0;
            padding: 10px 15px;
            transition: all 0.25s ease;
            width: 100%;

            @media(${mediumUp}) {
                padding: 15px;
            }

            &.selected {
                background: #f4f2ff;
                border-color: #7a63ff;
            }
        }
    }
`;

export default function Questions({ answers, onNext, question, selectedAnswer, setAnswer }) {
    return (
        r(Container, [
            r.h3([question]),
            r.div({ className: 'option-container' }, [
                answers.map((answer) => (
                    r(motion.div, {
                        className: 'option',
                        classSet: { selected: selectedAnswer === answer },
                        key: answer,
                        onClick: () => setAnswer(answer),
                        // transition: { duration: 0.000005 },
                        // whileHover: { scale: 1.03 },
                    }, [answer])
                ))
            ]),
        ])
    );
}
