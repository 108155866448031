import axios from 'axios';
import CryptoJS from 'crypto-js';

const publicKey = process.env.REACT_APP_PROMOTE_API_PUBLIC_KEY;
const privateKey = process.env.REACT_APP_PROMOTE_API_PRIVATE_KEY;

function generateTimestamp() {
    const now = new Date();

    const parts = {
        date: now.getUTCDate().toString().padStart(2, '0'),
        hour: now.getUTCHours().toString().padStart(2, '0'),
        month: (now.getUTCMonth() + 1).toString().padStart(2, '0'),
        year: now.getUTCFullYear().toString(),
    };

    return `${parts.year}-${parts.month}-${parts.date} ${parts.hour}`;
}

function createHash(content) {
    const now = generateTimestamp();

    return CryptoJS.HmacSHA512(content + now, privateKey);
}

export const authHttp = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {'Authorization': `Hash ${publicKey}`},
    transformRequest: [function (data, headers) {
        const stringifiedBody = data ? JSON.stringify(data) : '';
        headers['content-hash'] = createHash(stringifiedBody);

        return data;
    }, ...axios.defaults.transformRequest],
});

export default axios.create({ baseURL: process.env.REACT_APP_API_URL });
