import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';

const StyledModal = styled(Modal)`
    button {
        margin: auto;
        max-width: 500px;
    }

    img {
        max-height: 150px;
    }

    p {
        line-height: 1.5;
    }
`;

export default function IntroModal({
    campaign,
    promotion,
    showModal,
    toggleModal,
}) {
    const surveySize = campaign.getIn(['action', 'metadata', 'questions']).size;
    const product = campaign.get('product');
    const payout = `$${promotion.get('payload')}`;

    return (
        r(StyledModal, { showModal, toggleModal }, [
            r.img({ src: product.getIn(['media', 0, 'url']) }),
            r.h2(['Thanks for your purchase!']),
            r.p([
                `Get your ${payout} reward in 5 minutes. Just take
                a short ${surveySize} question survey.`
            ]),
            r(Button, { onClick: toggleModal }, [
                'Take the survey',
            ]),
        ])
    );
}
