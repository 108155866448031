import PropTypes from 'prop-types';
import r from 'render-dom';
import styled from 'styled-components';

const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: ${({ label }) => label ? '7px' : 0};
    max-width: 96vw;
    padding: 0 1px;
    position: relative;
    width: 100%;
`;

const Icon = styled.i`
    font-size: 14px;
    ${({ iconPosition }) => {
        if (iconPosition === 'left') {
            return 'left: 4px;';
        } else if (iconPosition === 'right') {
            return 'right: 7px;';
        }
        return null;
    }}
    margin-left: 5px;
    margin-top: 3px;
    padding-right: 0;
    position: absolute;
    top: 5px;
    z-index: 3;
`;

const InputSection = styled.section`
    display: flex;
    flex: 1;
    flex-direction: column;
    & label {
        font-size: 14px;
        font-weight: 500;
    }
`;

const Optional = styled.span`
    color: #bbb;
    font-weight: 400;
    padding-left: 5px;
`;

const StyledInput = styled.input`
    appearance: none;
    background-color: ${({ disabled }) => disabled ? '#f8f8f8' : 'transparent'};
    border: 2px solid #bbb;
    border-radius: 7px;
    box-sizing: border-box;
    font-family: "effra",sans-serif;
    font-size: 14px;
    outline: none;
    padding: 12px 10px;
    ${({ icon, iconPosition }) => {
        if (icon) {
            if (iconPosition === 'left') {
                return 'padding-left: 30px;';
            } else if (iconPosition === 'right') {
                return 'padding-right: 30px;';
            }
        }
        return null;
    }}
    width: 100%;
    z-index: 2;

    :disabled {
        color: #888;
        cursor: not-allowed;
    }

    :focus {
        border-color: #7a63ff;
    }

    ::placeholder {
        color: #999;
    }
`;

const Error = styled.span`
    color: #d20707;
    font-size: 12px;
    font-weight: 500;
    left: 3px;
    position: relative;
    top: -15px;
`;

export default function Input({
    autocomplete,
    className = '',
    containerStyle = {},
    disabled = false,
    icon,
    iconPosition = 'left',
    input,
    label,
    large = true,
    maxLength,
    meta = {},
    name,
    onBlur,
    onChange = Function.prototype,
    onIconClick,
    onKeyPress,
    onKeyUp,
    optional = false,
    placeholder,
    sectionStyle = {},
    style = {},
    type = 'text',
    value,
    ...props
}) {
    const hasMeta = Object.keys(meta).length;
    const inputName = input ? input.name : name;

    const customOnChange = (e) => {
        if (maxLength) {
            return onChange({
                ...e,
                target: {
                    ...e.target,
                    value: e.target.value.substring(0, maxLength),
                },
            });
        }

        return onChange(e);
    };

    return (
        r(InputSection, {
            style: sectionStyle,
        }, [
            r.label([
                r.span([
                    label,
                    r(Optional, { isRendered: optional }, [
                        'optional',
                    ]),
                ]),
                r(Container, {
                    className,
                    disabled,
                    label,
                    style: containerStyle,
                }, [
                    r(Icon, {
                        className: `icon-${icon}`,
                        iconPosition,
                        isRendered: icon,
                        onClick: onIconClick,
                    }),
                    r(StyledInput, {
                        ...props,
                        autocomplete,
                        disabled,
                        icon,
                        iconPosition,
                        large,
                        name: inputName,
                        onBlur,
                        onChange: input ? input.onChange : customOnChange,
                        onKeyPress: input ? input.onKeyPress : onKeyPress,
                        onKeyUp: input ? input.onKeyUp : onKeyUp,
                        placeholder,
                        style,
                        type,
                        value: input ? input.value : value,
                    }),
                ]),
            ]),
            r(Error, { isRendered: hasMeta && meta.error }, [meta.error]),
            r(Error, { isRendered: hasMeta && meta.warning }, [meta.warning]),
        ])
    );
}

Input.propTypes = {
    autocomplete: PropTypes.string,
    icon: PropTypes.string,
    input: PropTypes.object,
    large: PropTypes.bool,
    meta: PropTypes.object,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func,
    onKeyUp: PropTypes.func,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
};
