import { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import r from 'render-dom';
import { createGlobalStyle } from 'styled-components';

import { useWindowSize } from 'hooks';

import Campaign from './Campaign';
import Home from './Home';

const GlobalStyle = createGlobalStyle`
    html {
        height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        height: calc(var(--vh, 1vh) * 100);
    }
`;

const vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

export default function App() {
    const { height } = useWindowSize();
    const queryClient = new QueryClient();

    useEffect(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [height]);

    return (
        r(QueryClientProvider, { client: queryClient }, [
            r(ReactQueryDevtools, { initialIsOpen: false }),
            r(GlobalStyle),
            r(Router, [
                r(Switch, [
                    r(Route, { path: '/:code' }, [
                        r(Campaign),
                    ]),
                    // r(Route, { path: '/:code' }, [
                    //     r(Validate),
                    // ]),
                    r(Route, { path: '/' }, [
                        r(Home),
                    ]),
                ])
            ]),
        ])
    );
}
