import r from 'render-dom';
import styled from 'styled-components';

import { mediumUp } from 'constants/mediaQueries';

import reward from './reward.png';

const Container = styled.div`
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh) * 100 - 105px);
    padding: 0 5vw 5vh;

    button {
        margin: 25px 0 15px;

        @media(${mediumUp}) {
            max-width: 250px;
        }
    }

    h2 {
        margin: 20px 0 0;
    }

    p {
        line-height: 1.6;
        margin-top: 10px;
    }

    .body {
        background: #baaffe;
        color: #5948b9;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        text-align: center;
        width: 100%;

        @media(${mediumUp}) {
            align-items: center;
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            width: 100vw;
        }
    }

    .button-container {
        justify-content: flex-end;
    }

    .img-container {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        max-height: 150px;
        max-width: 250px;

        img {
            object-fit: contain;
            max-width: 100%;
        }
    }
`;

export default function Done({ campaign, promotion }) {
    const isWarranty = false;
    const payoutMethod = 'PayPal';
    const payout = `$${promotion.get('payload')}`;

    return (
        r(Container, [
            r.div({ className: 'body' }, [
                r.div({ className: 'img-container' }, [
                    r.img({ src: reward }),
                ]),
                r.div([
                    r.h2([
                        isWarranty
                            ? 'Your warranty is registered!'
                            : `${payout.replace('.00', '')} headed your way!`,
                    ]),
                    r.p([
                        `Allow 5 days for your information to be reviewed. Then we'll
                        send a payment through ${payoutMethod}.`
                    ]),
                ]),
            ]),
        ])
    );
}
