import r from 'render-dom';
import styled from 'styled-components';

import { mediumUp } from 'constants/mediaQueries';

const Container = styled.section`
    text-align: center;

    h3 {
        font-size: 28px;
        margin: 0 0 15px;
    }

    .number-progress {
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }

    .option-container {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-evenly;
    }

    .option {
        align-items: center;
        border: 2px solid #bbb;
        border-radius: 7px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 10px 0;
        padding: 20px;
        transition: all 0.25s ease;
        width: 100%;

        @media(${mediumUp}) {
            width: 10%;
        }

        &.selected {
            background: #f4f2ff;
            border-color: #7a63ff;
        }
    }
`;

export default function Questions({ answers, question, selectedAnswer, setAnswer }) {
    return (
        r(Container, [
            r.h3([question]),
            r.div({ className: 'option-container' }, [
                answers.map((answer) => (
                    r.div({
                        classSet: { option: true, selected: selectedAnswer === answer },
                        onClick: () => setAnswer(answer),
                    }, [answer])
                ))
            ]),
        ])
    );
}
