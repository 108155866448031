import { motion } from 'framer-motion';
import r from 'render-dom';
import styled from 'styled-components';

import { mediumUp } from 'constants/mediaQueries';

const Container = styled.div`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto 6vw;
    padding: 5px 0 40px;
    position: relative;

    @media(${mediumUp}) {
        padding: 15px 0 60px;
    }

    .circle {
        background: #bbbbbb;
        border: 6px solid #fff;
        border-radius: 50%;
        height: 12px;
        transition: all 0.6s ease;
        transition-delay: 0.7s;
        width: 12px;

        &.active {
            background: #7a63ff;
            border: 6px solid #baafff;
        }

        &.completed {
            background: #7a63ff;
            transition-delay: 0s;
        }
    }

    .point {
        border: 3px solid white;
        border-radius: 50%;
        position: relative;
    }

    .label {
        color: #555;
        line-height: 1.4;
        left: 50%;
        position: absolute;
        text-align: center;
        text-transform: capitalize;
        top: 30px;
        transform: translateX(-50%);
        transition: all 0.6s ease;
        transition-delay: 0.7s;

        &.active {
            font-weight: bold;
        }

        &.active, &.completed {
            color: #7a63ff;
        }
    }
`;

export default function Steps({ activeStep, steps }) {
    const activeStepIndex = steps.findIndex((val) => val === activeStep);
    const percentageFinished = (activeStepIndex / (steps.length - 1)) * 100;

    return (
        r(Container, [
            steps.map((step, index) => {
                const active = activeStepIndex === index;
                const completed = activeStepIndex > index;

                return (
                    r.div({ className: 'point', key: step }, [
                        r.div({ classSet: {
                            active,
                            completed,
                            circle: true,
                        } }),
                        r.div({
                            classSet: { active, completed, label: true },
                        }, [step]),
                    ])
                );
            }),
            // r.div({ className: 'point' }, [
            //     r.div({ classSet: { active: false, circle: true } }),
            //     r.div({ className: 'label' }, ['Payment Info'])
            // ]),
            // r.div({ className: 'point' }, [
            //     r.div({ classSet: { active: false, circle: true } }),
            //     r.div({ className: 'label' }, ['Done']),
            // ]),
            r(motion.div, {
                animate: { width: `${percentageFinished}%` },
                style: {
                    borderBottom: '6px solid #baafff',
                    position: 'absolute',
                    zIndex: -1,
                },
                transition: { duration: 1, type: 'tween' },
            }),
            r.div({
                style: {
                    borderBottom: '2px solid #e0e0e0',
                    position: 'absolute',
                    width: '100%',
                    zIndex: -2,
                },
            }),
        ])
    );
}
