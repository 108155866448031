import { Fragment, useEffect, useState } from 'react';
import r from 'render-dom';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';

import Steps from 'components/ui/Steps';

import Done from './Done';
import IntroModal from './IntroModal';
import Payment from './Payment';
import Survey from './Survey';
import Warranty from './Warranty';

const steps = ['survey', 'payment', 'done'];

export default function Campaign({ campaign, promotion }) {
    const [showModal, setShowModal] = useState(true);
    const history = useHistory();
    const params = useParams();
    const { code, step } = params;
    const action = campaign.get('action');
    const questions = action.getIn(['metadata', 'questions']);
    const [survey, setSurvey] = useState(questions);
    const hasCompletedSurvey = survey.filter((q) => q.get('selected')).size === questions.size;

    useEffect(() => {
        if (campaign.get('id') && !hasCompletedSurvey && step !== 'survey') {
            history.push(`/${code}/survey`);
        }
    }, [campaign]);

    return (
        r(Fragment, [
            r(Steps, {
                activeStep: step || '',
                // isRendered: !isFetching && step !== 'done',
                steps,
            }),
            r(Switch, [
                r(Route, { path: '/:code/done' }, [
                    r(Done, { campaign, code, promotion }),
                ]),
                r(Route, { path: '/:code/warranty' }, [
                    r(Warranty, { code }),
                ]),
                r(Route, { path: '/:code/survey' }, [
                    r(IntroModal, {
                        campaign,
                        promotion,
                        showModal,
                        toggleModal: () => setShowModal(false),
                    }),
                    r(Survey, { campaign, code, survey, setSurvey }),
                ]),
                r(Route, { path: '/:code/payment' }, [
                    r(Payment, { campaign, code, promotion, survey }),
                ]),
            ]),
        ])
    );
}
