import r from 'render-dom';
import styled from 'styled-components';

import Loading from 'components/ui/Loading';

const LoadingSection = styled.section`
    align-items: center;
    background: linear-gradient(185deg, #fff, rgb(207 199 255));
    display: flex;
    height: calc(var(--vh) * 100);
    justify-content: center;

    h2 {
        color: #7a63ff;
        padding-left: 20px;
    }
`;

export default function LoadingCampaign() {
    return (
        r(LoadingSection, [
            r(Loading, [
                r.h2(['Loading...']),
            ]),
        ])
    );
}
