import { fromJS } from 'immutable';
import { useState } from 'react';
import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import ProgressBar from 'components/ui/ProgressBar';
import Steps from 'components/ui/Steps';

import Questions from './Questions';

const Container = styled.div`
    padding: 0 5vw;

    .button-container {
        display: flex;
        margin-top: 25px;

        .backButton {
            align-items: center;
            display: flex;
            margin-right: 25px;
            padding: 0px;
            transform: none;
            width: auto;

            i {
                font-size: 28px;
                font-style: normal;
                position: relative;
                padding-right: 7px;
                top: -3px;
            }
        }
    }
`;

    const QuestionsContainer = styled.div`
    padding: 0 5vw;

    .number-progress {
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
`;

const steps = ['Survey', 'Payment', 'Done'];
const initialQuestions = fromJS([
    {
        answers: [
            'Excellent',
            'Good',
            'Fair',
            'Poor',
            'Terrible',
        ],
        question: 'Please rate the QUALITY of the product',
        selectedAnswer: '',
    },
    {
        answers: [
            'Excellent',
            'Good',
            'Fair',
            'Poor',
            'Terrible',
        ],
        question: 'Please rate the PRICE of the product',
        selectedAnswer: '',
    },
    {
        answers: [
            'Very likely',
            'Somewhat likely',
            'Neither likely or unlikely',
            'Somewhat unlikely',
            'Very unlikely',
        ],
        question: 'How likely are you to recommend the product to a friend?',
        selectedAnswer: '',
    },
]);

export default function Survey() {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [questions, setQuestions] = useState(initialQuestions);
    const { answers, question, selectedAnswer } = questions.get(activeQuestionIndex).toJS();
    const lastStepIndex = steps.length - 1;

    function onBack() {
        if (activeStepIndex > 0) {
            setActiveStepIndex(activeStepIndex - 1);
        }
    }

    function onNext() {
        if (activeQuestionIndex < questions.size - 1) {
            return setActiveQuestionIndex(activeQuestionIndex + 1);
        } else if (activeStepIndex < lastStepIndex) {
            return setActiveStepIndex(activeStepIndex + 1);
        }
    }

    function setAnswer(answer) {
        setQuestions(questions.setIn([activeQuestionIndex, 'selectedAnswer'], answer));
    }

    return (
        r.div([
            r(Steps, {
                activeStepIndex,
                steps,
            }),
            r(QuestionsContainer, {
                isRendered: activeStepIndex === 0,
            }, [
                r(ProgressBar, {
                    percentageCompleted: (activeQuestionIndex + 1) / questions.size,
                }),
                r.div({ className: 'number-progress' }, [
                    `${activeQuestionIndex + 1} of ${questions.size} Questions`,
                ]),
                r(Questions, {
                    answers,
                    question,
                    selectedAnswer,
                    setAnswer,
                }),
            ]),
            r(Container, [
                r.div({ className: 'button-container' }, [
                    r(Button, {
                        classSet: { backButton: true, ghost: true },
                        isRendered: activeStepIndex > 0,
                        onClick: onBack,
                    }, [
                        r.i([
                            '\u2039',
                        ]),
                        ' Back',
                    ]),
                    r(Button, {
                        isRendered: activeStepIndex < lastStepIndex,
                        onClick: onNext,
                    }, [
                        'Next'
                    ]),
                    r(Button, {
                        isRendered: activeStepIndex === lastStepIndex,
                        onClick: () => console.log('done'),
                    }, [
                        'Finish'
                    ]),
                ]),
            ]),
        ])
    );
}
