import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import { smallDown } from 'constants/mediaQueries';

import starRating from './star_rating.mp4';

const Container = styled.section`
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;

    h1 {
        margin-top: 0;
    }

    img {
        height: 100px !important;
    }

    video {
        left: -8px;
        margin: 10px 0 15px;
        max-width: 200px;
        position: relative;
    }

    .rate-button {
        button {
            margin: auto;
        }
    }

    .title {
        height: 19px;
        margin-top: 12px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        width: 400px;

        @media(${smallDown}) {
            max-width: 100%;
        }

        span {
            white-space: nowrap;
        }
    }
`;

function capitalize(str) {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}

export default function RateProduct({ listing, onClick, product }) {
    const reviewUrl = new URL(listing.get('reviewUrl'));
    const reviewHostname = capitalize(
        reviewUrl.hostname.replace('www.', '').replace('.com', '')
    );

    return (
        r(Container, [
            r.h1([
                'Give us a rating on ',
                reviewHostname,
            ]),
            r.div({ style: { maxWidth: '100%' } }, [
                r.img({ src: product.getIn(['media', 0, 'url']) }),
                r.div({ className: 'title' }, [
                    r.span([product.get('name')]),
                ]),
                r.video({ autoPlay: true, muted: true, playsInline: true, src: starRating }),
            ]),
            r(Button, {
                className: 'rate-button',
                href: reviewUrl.href,
                onClick,
            }, [
                'Rate on ',
                reviewHostname,
            ]),
        ])
    );
}
