import { motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import r from 'render-dom';
import styled from 'styled-components';

import { targetBlank } from 'utils/helpers';

const MotionButton = styled(motion.button)`
    background: #7a63ff;
    background: linear-gradient(180deg,rgb(122,99,255),rgb(146 129 255));
    border: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    padding: 15px;
    width: 100%;

    &.disabled {
        background: #999;
        cursor: not-allowed;
    }

    &.ghost {
        background: #fff;
        color: #000;
    }
`;

const StyledA = styled.a`
    display: block;
    text-decoration: none;
    width: 100%;
`;

const StyledLink = styled(Link)`
    display: block;
    text-decoration: none;
    width: 100%;
`;

export default function Button({
    children,
    className = '',
    classSet = {},
    disabled,
    href = '',
    // icon,
    isLoading,
    label,
    loadOnClick = false,
    onClick = Function.prototype,
    ...props
}) {
    const [isLoadingState, setIsLoadingState] = useState(false);
    const showLoading = isLoading || isLoadingState;
    const isDisabled = disabled || showLoading;
    const buttonClasses = href
        ? { disabled: isDisabled }
        : { [className]: true, disabled: isDisabled, ...classSet };

    function handleClick(...args) {
        if (loadOnClick) {
            setIsLoadingState(true);
        }

        onClick(...args);
    }

    // if (href) {
    //     if (href.includes('//')) {
    //         return r(StyledA, targetBlank(href), [button]);
    //     }

    //     return r(StyledLink, { to: href }, [button]);
    // }

    const button = (
        r(MotionButton, {
            ...props,
            classSet: buttonClasses,
            disabled: isDisabled,
            onClick: handleClick,
            whileHover: { scale: !disabled ? 1.04 : 1 },
            whileTap: { scale: !disabled ? 0.97 : 1 },
        }, [
            r(Fragment, { isRendered: !showLoading }, [
                children || r.span({ isRendered: label }, [label]),
            ]),
            r.span({ isRendered: showLoading }, [
                'Loading...',
                // r(Spinner, {
                //     color: ghost ? '#333' : '#fff',
                //     size: 20,
                //     style: { padding: 0 }
                // }),
            ]),
        ])
    );

    if (href && !isDisabled) {
        if (href.includes('//')) {
            return r(StyledA, {
                classSet: { [className]: true, disabled: isDisabled, ...classSet },
                ...targetBlank(href),
            }, [button]);
        }

        return r(StyledLink, {
            classSet: { [className]: true, disabled: isDisabled, ...classSet },
            to: href,
        }, [button]);
    }

    return button;
}
