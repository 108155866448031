import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import { mediumUp, smallDown } from 'constants/mediaQueries';
import { authHttp } from 'utils/http';

import payment from './payment.png';
import paypalLogo from './paypal-logo.jpg';
// import venmoLogo from './venmo-logo.png';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(var(--vh) * 100 - 90px);
    justify-content: space-between;
    overflow: scroll;
    padding: 0 5vw;

    @media(${mediumUp}) {
        min-height: calc(var(--vh) * 100 - 115px);
    }

    p {
        line-height: 1.6;
        margin-top: 0;
        text-align: center;
    }

    .button-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 25px 0 0;

        .backButton {
            align-items: center;
            display: flex;
            margin-right: 25px;
            padding: 0px;
            transform: none;
            width: auto;

            i {
                font-size: 28px;
                font-style: normal;
                position: relative;
                padding-right: 7px;
                top: -3px;
            }
        }

        .next-button {
            margin-bottom: 20px;

            @media(${mediumUp}) {
                max-width: 200px;
            }
        }
    }

    .form-section {
        align-items: center;
        display: flex;
        flex-direction: column;
    }

    .form-section, .payout-section {
        @media(${mediumUp}) {
            margin: 0 auto;
            max-width: 500px;
        }
    }

    .hero-img {
        height: 100%;
        margin: auto auto 10px;
        max-width: 40vw;
        width: 18vw;

        @media(${smallDown}) {
            max-width: unset;
            width: 60vw;
        }
    }

    .payout-section {
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        padding: 20px 0;
        width: 100%;

        .img-container {
            align-items: center;
            border: 2px solid #bbb;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            height: 94px;
            justify-content: center;
            position: relative;
            transition: all 0.25s ease;
            width: 94px;

            .selected-bg {
                background: rgb(122, 99, 255);
                bottom: 0;
                left: 0;
                opacity: 0;
                position: absolute;
                right: 0;
                top: 0;
                transition: all 0.25s ease;
            }

            &.selected {
                border-color: #7a63ff;

                .selected-bg {
                    opacity: 0.14;
                }
            }
        }

        img {
            max-height: 50px;
            max-width: 50px;
        }
    }

    .pay-me {
        display: flex;
        flex-direction: column;
        margin: auto;
        max-width: 500px;
        padding: 20px 0;

        .paypal-section {
            align-items: center;
            display: flex;
            justify-content: space-evenly;

            img {
                max-height: 50px;
                max-width: 50px;
            }

            p {
                color: #444;
                margin: 0;
                padding-left: 20px;
                text-align: left;
            }
        }
    }

`;

export default function Payment({ campaign, code, promotion, survey }) {
    const history = useHistory();
    const payoutAmount = promotion.get('payload');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const disablePayout = !email.length || !name.length;
    // const [selectedPayout, setSelectedPayout] = useState();

    const sectionStyle = { marginBottom: 15, maxWidth: '450px', width: '100%' };

    async function claimReward(paymentInfo, campaignId) {
        const order = await authHttp({
            data: { code, email, name },
            method: 'POST',
            url: `/reward-codes/${campaign.get('id')}/claim`,
        }).then((res) => res.data);

        return authHttp({
            data: { deliverable: { survey } },
            method: 'POST',
            url: `/reward-codes/${order.id}/deliverable`,
        });
    }

    return (
        r(Container, [
            r.div({ className: 'form-section' }, [
                r.img({ className: 'hero-img', src: payment }),
                r.p([
                    `Thanks for completing the survey. We need some information
                    to payout your $${payoutAmount} reward.`,
                ]),
                r(Input, {
                    name: 'name',
                    onChange: (e) => setName(e.target.value),
                    placeholder: 'Full name',
                    sectionStyle,
                    value: name,
                }),
                r(Input, {
                    name: 'email',
                    onChange: (e) => setEmail(e.target.value),
                    placeholder: 'PayPal email address',
                    sectionStyle,
                    type: 'email',
                    value: email,
                }),
                r(Input, {
                    isRendered: false,
                    name: 'phone',
                    placeholder: 'Phone number',
                    sectionStyle,
                    type: 'tel',
                }),
                r(Input, {
                    isRendered: false,
                    pattern: 'd{5}-?(d{4})?',
                    placeholder: 'Zip code',
                    sectionStyle,
                }),
            ]),
            r.div({ className: 'pay-me' }, [
                r.div({ className: 'paypal-section' }, [
                    r.img({ src: paypalLogo }),
                    r.p([
                        `Your payment will be sent via PayPal. Make sure the email
                        address above is your PayPal email address.`
                    ]),
                ]),
                // r(Button, {
                //     classSet: { disabled: true, 'next-button': true },
                //     disabled: true,
                //     onClick: () => {
                //         history.push(`/${code}/done`);
                //         console.log(name, email);
                //     },
                // }, [
                //     'Pay Me'
                // ]),
            ]),
            // r.div({ className: 'payout-section' }, [
            // r.div({
            //     classSet: {
            //         'img-container': true,
            //         selected: selectedPayout === 'paypal'
            //     },
            //     onClick: () => setSelectedPayout('paypal'),
            // }, [
            //     r.div({ className: 'selected-bg' }),
            //     r.img({ src: paypalLogo }),
            // ]),
            // r.span(['or']),
            // r.div({
            //     classSet: {
            //         'img-container': true,
            //         selected: selectedPayout === 'venmo'
            //     },
            //     onClick: () => setSelectedPayout('venmo'),
            // }, [
            //     r.div({ className: 'selected-bg' }),
            //     r.img({ src: venmoLogo }),
            // ]),
            // ]),
            r.div({ className: 'button-container' }, [
                // r(Button, {
                //     classSet: { backButton: true, ghost: true },
                //     onClick: () => {
                //         history.push(`/${code}/survey`);
                //     }
                // }, [
                //     r.i([
                //         '\u2039',
                //     ]),
                //     ' Back',
                // ]),
                r(Button, {
                    classSet: { disabled: disablePayout, 'next-button': true },
                    disabled: disablePayout,
                    onClick: async () => {
                        try {
                            await claimReward();
                        } catch(e) {
                            // TODO: show error banner
                            console.error(e);
                            return;
                        }

                        return history.push(`/${code}/done`);
                    },
                }, [
                    'Pay Me'
                ]),
            ])
        ])
    );
}
