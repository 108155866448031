import { AnimatePresence, motion } from 'framer-motion';
import r from 'render-dom';
import styled from 'styled-components';

const Container = styled.div`
    .background {
        align-items: center;
        background-color: rgba(0,0,0,0.5);
        bottom: 0;
        display: flex;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 2;
    }

    .card {
        background-color: rgb(255,255,255);
        border-radius: 10px;
        box-shadow: none;
        box-sizing: border-box;
        color: rgba(0,0,0,0.87);
        max-width: 750px;
        padding: 30px;
        position: fixed;
        text-align: center;
        transform: translate(-50%,-50%);
        width: 95vw;
        z-index: 3;
    }
`;

export default function Modal({ className = '', children, showModal, toggleModal }) {
    const cardTransition = { mass: 0.5, type: 'spring' };

    return (
        r(AnimatePresence, [
            r(Container, { isRendered: showModal }, [
                r(motion.div, {
                    animate: { opacity: 1 },
                    className,
                    classSet: { background: true },
                    exit:  { opacity: 1 },
                    initial: { opacity: 0 },
                    onClick: toggleModal,
                    transition: { duration: 0.2 },
                }, [
                    r(motion.div, {
                        animate: {
                            transition: { ...cardTransition, delay: 0.4 },
                            y: 0,
                        },
                        className: 'card',
                        exit:  { y: '100vh' },
                        initial: { x: 0, y: '100vh' },
                        onClick: (e) => e.stopPropagation(),
                        transition: cardTransition,
                    }, [
                        children,
                    ]),
                ]),
            ]),
        ])
    );
}
