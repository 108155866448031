import { motion } from 'framer-motion';
import r from 'render-dom';
import styled from 'styled-components';

const Container = styled.div`
    position: relative;

    .background, .progress {
        border-radius: 10px;
        height: 8px;
    }

    .background {
        background: #e0e0e0;
    }

    .progress {
        position: absolute;
        background: linear-gradient(185deg, rgb(184, 173, 255), #7a63ff);
        position: absolute;
    }
`;

export default function ProgressBar({ percentageCompleted }) {
    return (
        r(Container, [
            r(motion.div, {
                animate: { width: `${percentageCompleted * 100}%` },
                className: 'progress',
                transition: { duration: 1, type: 'tween' },
            }),
            r.div({ className: 'background' }),
        ])
    );
}
