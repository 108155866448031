import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import Modal from 'components/ui/Modal';
import ProgressBar from 'components/ui/ProgressBar';
import { mediumUp } from 'constants/mediaQueries';

import Questions from './Questions';
import RateProduct from './RateProduct';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(var(--vh) * 100 - 90px);
    padding: 0 5vw;

    @media(${mediumUp}) {
        min-height: calc(var(--vh) * 100 - 115px);
    }

    .button-container {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        margin: 20px 0 0;

        .backButton {
            align-items: center;
            display: flex;
            margin-right: 25px;
            padding: 0px;
            transform: none;
            width: auto;

            i {
                font-size: 28px;
                font-style: normal;
                position: relative;
                position: relative;
                padding-right: 7px;
                top: -3px;
            }
        }

        .next-button {
            @media(${mediumUp}) {
                max-width: 200px;
            }
        }
    }

    .close-modal {
        cursor: pointer;
        display: inline-block;
        margin-top: 20px;
        text-decoration: underline;
    }

    .number-progress {
        display: flex;
        justify-content: center;
        margin: 10px 0 15px;
    }
`;


export default function Survey({ campaign, code, setSurvey, survey: questions, }) {
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
    const [showReviewModal, setShowReviewModal] = useState(false);
    const history = useHistory();
    const { choices, label, selected } = questions.get(activeQuestionIndex).toJS();
    const lastQuestionIndex = questions.size - 1;
    const answeredQuestions = selected ? activeQuestionIndex + 1 : activeQuestionIndex;
    const listing = campaign.get('listing');
    const product = campaign.get('product');

    function toggleModal() {
        setShowReviewModal(!showReviewModal);
    }

    function goToPayment() {
        history.push(`/${code}/payment`);
    }

    function onBack() {
        if (activeQuestionIndex > 0) {
            setActiveQuestionIndex(activeQuestionIndex - 1);
        }
    }

    function onNext() {
        if (activeQuestionIndex < questions.size - 1) {
            return setActiveQuestionIndex(activeQuestionIndex + 1);
        }
    }

    function setAnswer(answer) {
        const updatedQuestions = questions.setIn([activeQuestionIndex, 'selected'], answer);
        setSurvey(updatedQuestions);
    }

    return (
        r(Container, [
            r.div([
                r(ProgressBar, {
                    percentageCompleted: answeredQuestions / questions.size,
                }),
                r.div({ className: 'number-progress' }, [
                    `${activeQuestionIndex + 1} of ${questions.size} Questions`,
                ]),
                r(Questions, {
                    answers: choices,
                    onNext: activeQuestionIndex < lastQuestionIndex ? onNext : toggleModal,
                    question: label,
                    selectedAnswer: selected,
                    setAnswer,
                }),
                r(Modal, { showModal: showReviewModal, toggleModal }, [
                    r(RateProduct, { listing, onClick: goToPayment, product }),
                    r.div({
                        className: 'close-modal',
                        onClick: goToPayment,
                    }, [
                        'No thanks',
                    ]),
                ]),
            ]),
            r.div({ className: 'button-container' }, [
                r(Button, {
                    className: 'next-button',
                    disabled: !selected,
                    isRendered: activeQuestionIndex < lastQuestionIndex,
                    onClick: onNext,
                }, [
                    'Next'
                ]),
                r(Button, {
                    className: 'next-button',
                    disabled: !selected,
                    isRendered: activeQuestionIndex === lastQuestionIndex,
                    onClick: toggleModal,
                }, [
                    'Next: Payment'
                ]),
                r(Button, {
                    classSet: { backButton: true, ghost: true },
                    isRendered: activeQuestionIndex > 0,
                    onClick: onBack,
                }, [
                    r.i([
                        '\u2039',
                    ]),
                    ' Back',
                ]),
            ]),
        ])
    );
}
