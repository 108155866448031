import { useQuery } from 'react-query';

import { authHttp } from 'utils/http';

export default function validateCode(code) {
    return useQuery(
        ['validate', code],
        () => authHttp({
            method: 'GET',
            params: { code, embeds: 'campaign.action,campaign.listing,campaign.product.media,promotion' },
            url: '/reward-code/validate',
        }).then((res) => res.data),
        { retry: false }
    )
}
