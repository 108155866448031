import { fromJS } from 'immutable';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import r from 'render-dom';

import validateCode from 'hooks/useValidateCode';

import Campaign from './Campaign';
import Loading from './Loading';

export default function CampaignWrapper() {
    const { code } = useParams();
    const { data, isError, isLoading } = validateCode(code);

    if (isLoading) {
        return r(Loading);
    }

    if (isError) {
        return (
            r(Redirect, {
                to: {
                    pathname: '/',
                    search: `?code=${code}&invalidCode=1`,
                }
            })
        );
    }

    if (data?.campaign?.state !== 'PUBLISHED') {
        return (
            r(Redirect, {
                to: {
                    pathname: '/',
                    search: `?code=${code}&inactive=1`,
                }
            })
        );
    }

    if (data) {
        const campaign = fromJS(data.campaign);
        const promotion = fromJS(data.promotion);
        const actionType = campaign.getIn(['action', 'type']);
        const isSurvey = actionType === 'SURVEY';

        return (
            r(Switch, [
                r(Route, { path: '/:code/:step' }, [
                    r(Campaign, { campaign, promotion })
                ]),
                r(Redirect, {
                    to: { pathname: `/${code}/${isSurvey ? 'survey' : 'warranty'}` },
                }),
            ])
        );
    }

}
