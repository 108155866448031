import { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import r from 'render-dom';
import styled from 'styled-components';

import Button from 'components/ui/Button';
import Input from 'components/ui/Input';
import { mediumUp, smallDown } from 'constants/mediaQueries';

import hello from './hello.png';

const Container = styled.div`
    margin: auto;
    max-width: 600px;
    padding: 8vh 8vw 2vh;
    text-align: center;

    @media(${smallDown}) {
        padding-top: 2vh;
    }

    img {
        max-height: calc(var(--vh) * 40);
        max-width: 100%;
    }

    input {
        font-size: 18px;
        text-align: center;
    }

    p {
        line-height: 1.7;
        margin-bottom: 30px;
    }

    .get-started {
        @media(${mediumUp}) {
            margin-left: 20px;
            max-width: 200px;
        }
    }

    .input-section {
        display: flex;

        @media(${smallDown}) {
            flex-direction: column;

            button {
                margin-top: 20px;
            }
        }

        .code-input {
            display: flex;
            flex: 1;
            flex-direction: column;
            position: relative;

            p {
                color: #e00000;
                line-height: 1.2;
                margin: 5px 0 10px 0;
                padding-left: 8px;
                text-align: left;
            }

            @media(${smallDown}) {
                p {
                    margin-bottom: 0;
                }
            }
        }
    }
`;

function addDashes(val) {
    if (val.length < 13) {
        const valArray = val.split('-');
        const lastIndex = valArray.length - 1;
        const lastEl = valArray[lastIndex];

        if (lastEl.length > 3) {
            valArray[lastIndex] = `${lastEl.slice(0, 3)}-${lastEl.slice(3)}`;
        }

        return valArray.join('-');
    }

    return val;
}

export default function Home() {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const [codeInput, setCodeInput] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const codeTooShort = codeInput.split('-').length < 4;
    const code = searchParams.get('code');
    const isInactive = searchParams.get('inactive');
    const isInvalid = searchParams.get('invalidCode');
    const history = useHistory();
    const inactiveCodeMessage = 'This code has expired';
    const invalidCodeMessage = 'Invalid code. Please check the code and try again.';

    useEffect(() => {
        isInactive && setErrorMessage(inactiveCodeMessage);
        isInvalid && setErrorMessage(invalidCodeMessage);
        code && setCodeInput(code);
    }, [code, isInvalid]);

    return (
        r(Container, [
            r.img({ src: hello }),
            r.h1(['Claim your Reward!']),
            r.p([
                `To get started enter your unique code below. Then answer a few
                questions to receive your free reward. This will take less than
                5 minutes.`
            ]),
            r.div({ className: 'input-section' }, [
                r.div({ className: 'code-input' }, [
                    r(Input, {
                        onChange: (e) => {
                            const inputValue = e.target.value.toUpperCase();
                            setCodeInput(addDashes(inputValue));
                        },
                        placeholder: 'A1B-C2D-EFG-123456',
                        value: codeInput,
                    }),
                    r.p({ isRendered: errorMessage }, [errorMessage]),
                ]),
                r.div([
                    r(Button, {
                        classSet: {
                            disabled: codeTooShort,
                            'get-started': true,
                        },
                        disabled: codeTooShort,
                        onClick: () => { history.push(`/${codeInput}`); },
                    }, ['Get Started']),
                ]),
            ]),
        ])
    );
}
