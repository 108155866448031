import { Fragment } from 'react';
import r from 'render-dom';
import styled, { keyframes } from 'styled-components';

import LoadingSvg from './Loading';

const transitionDuration = 2;
const pathLength = 157;

const escalade = keyframes`
    0% {
        stroke-dasharray: 0 ${pathLength}px;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: ${pathLength - 1}px ${pathLength}px;
        stroke-dashoffset: 0;
    }
    100% {
        stroke-dasharray: ${pathLength - 1}px ${pathLength}px;
        stroke-dashoffset: -${pathLength - 1}px;
    }
`;

const slide = keyframes`
    0% {
        transform: translateY(-50px);
    }
    100% {
        transform: translateY(50px);
    }
`;

const Container = styled.div`
    svg {
        overflow: visible;
        transform: rotate(90deg);
        width: 100px;
        height: 150px;

        g {
            animation: ${slide} ${transitionDuration}s linear infinite;

            &:nth-child(2) {
                animation-delay: ${transitionDuration / 4}s;

                path {
                    animation-delay: ${transitionDuration / 4}s;
                    stroke-dasharray: 0px ${pathLength + 1}px;
                    stroke-dashoffset: 1px;
                }
            }
        }

        path {
            stroke: url(#gradient);
            stroke-width: 20px;
            stroke-linecap: round;
            fill: none;
            stroke-dasharray: 0 ${pathLength}px;
            stroke-dashoffset: 0;
            animation: ${escalade} ${transitionDuration}s cubic-bezier(0.8, 0, 0.2, 1) infinite;
        }
    }
`;

export default function Loading({ children = '' }) {
    return (
        r(Container, [
            r(LoadingSvg),
            r(Fragment, { isRendered: children }, [children]),
        ])
    )
}
